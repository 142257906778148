import { Box, Button, Container, FormControl, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, Tab, Tabs, TextField, Typography } from "@mui/material";
import { useFormik } from "formik";
import { useState } from "react";
import * as yup from 'yup';
import EmployeeDocumentation from "./EmployeeDocumentation";
import { EmployeeProps, JobRoles } from "./EmployeeProps";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

/**
 * 
 * @param {EmployeeProps} props 
 * @returns 
 */
function Employee(props) {
    const employee = props.data;
    const onUpdate = props.onUpdate;
    const onCancel = props.onCancel;

    const validationSchema = yup.object({
        firstName: yup.string("First Name")
            .required("First Name is required"),
        lastName: yup.string("Last Name")
            .required("Last Name is required")
    });

    const formik = useFormik({
        initialValues: { ...employee },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            onUpdate(values);
        }
    });

    // Tab state.
    const [tabValue, setTabValue] = useState(0);
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    function tabProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    return (
        <Container sx={{ mt: 1 }}>
            <Typography variant="h5">Manage Employee</Typography>
            <br />

            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={tabValue} onChange={handleTabChange} aria-label="employee-manage-tabs">
                    <Tab label="Personal Information" {...tabProps(0)} />
                    <Tab label="Job Details" {...tabProps(1)} />
                    <Tab label="Documentation" {...tabProps(2)} />
                </Tabs>
            </Box>



            <form onSubmit={formik.handleSubmit}>
                <input type="hidden" name="id" value={formik.values.id} />

                <TabPanel value={tabValue} index={0}>
                    <TextField fullWidth
                        id="firstName"
                        name="firstName"
                        label="First Name"
                        value={formik.values.firstName}
                        onChange={formik.handleChange}
                        error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                        helperText={formik.touched.firstName && formik.errors.firstName}
                        sx={{ mt: 2 }}>
                    </TextField>

                    <TextField fullWidth
                        id="lastName"
                        name="lastName"
                        label="Last Name"
                        value={formik.values.lastName}
                        onChange={formik.handleChange}
                        error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                        helperText={formik.touched.lastName && formik.errors.lastName}
                        sx={{ mt: 2 }}>
                    </TextField>

                    <TextField fullWidth
                        id="phoneNumber"
                        name="phoneNumber"
                        label="Phone Number"
                        value={formik.values.phoneNumber}
                        onChange={formik.handleChange}
                        error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
                        helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
                        sx={{ my: 2 }}>
                    </TextField>
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                    <FormControl fullWidth sx={{ mt: 2 }}>
                        <InputLabel id="employee-job-role">Role</InputLabel>
                        <Select
                            labelId="employee-job-role"
                            id="jobRole"
                            name="jobRole"
                            label="Job Role"
                            value={formik.values.jobRole}
                            onChange={formik.handleChange}
                            error={formik.touched.jobRole && Boolean(formik.errors.jobRole)}>
                            {
                                JobRoles.map((role, index) => {
                                    return (
                                        <MenuItem key={role} value={role}>{role}</MenuItem>
                                    )
                                })
                            }
                        </Select>
                    </FormControl>

                    <TextField fullWidth
                        id="hireDate"
                        name="hireDate"
                        label="Hire Date"
                        type="date"
                        value={formik.values.hireDate}
                        onChange={formik.handleChange}
                        error={formik.touched.hireDate && Boolean(formik.errors.hireDate)}
                        helperText={formik.touched.hireDate && formik.errors.hireDate}
                        sx={{ mt: 2 }}>
                    </TextField>

                    <FormControl fullWidth sx={{ mt: 2 }}>
                        <InputLabel htmlFor="outlined-adornment-amount">Pay Rate</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-amount"
                            startAdornment={<InputAdornment position="start">$</InputAdornment>}
                            label="Pay Rate"
                            value={formik.values.payRate}
                            onChange={formik.handleChange}
                            error={formik.touched.payRate && Boolean(formik.errors.payRate)}
                            helperText={formik.touched.payRate && formik.errors.payRate}
                        />
                    </FormControl>
                </TabPanel>

                <TabPanel value={tabValue} index={2}>
                    <EmployeeDocumentation></EmployeeDocumentation>
                </TabPanel>
                
                <Box sx={{ mt: 1 }} style={{ textAlign: "center" }}>
                    <Button type="submit" color="primary" variant="contained" sx={{ width: "45%" }}>Save</Button>
                    <span> </span>
                    <Button color="secondary" variant="contained" onClick={onCancel} sx={{ width: "45%" }}>Cancel</Button>
                </Box>
            </form>
        </Container>
    )
}

export default Employee;