// ag-theme-material

import { Button, Container, Drawer, Grid, Typography } from '@mui/material';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-material.css';
import { AgGridReact } from 'ag-grid-react';
import { useState } from 'react';
import Employee from './Employee';
import { EmployeeRecord } from './EmployeeProps';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

function Employees() {
    const [employees, setEmployees] = useState([
        { id: 0, firstName: "Jane", lastName: "Smith", jobRole: "Hygentist", hireDate: '2020-01-01', phoneNumber: "" },
        { id: 1, firstName: "Suzie", lastName: "Rogers", jobRole: "Hygentist", hireDate: '2022-01-01', phoneNumber: "" }
    ]);

    const [currentEmployee, setCurrentEmployee] = useState({});
    const [isOpen, setIsOpen] = useState(false);

    const drawerCloseHandler = (event) => {
        setIsOpen(false);
    };

    const addEmployeeClickHandler = event => {
        setCurrentEmployee(EmployeeRecord.default);
        setIsOpen(true);
    };

    const rowClickHandler = (params) => {
        setCurrentEmployee(params.data);
        setIsOpen(true);
    };

    const buttonCellRenderer = (params) => {
        return (
            <>
                <Button size="small" variant='outlined' color='error'>Delete</Button>
            </>
        )
    };

    const valueGetter = (params) => {
        const employee = params.data;
        return employee.lastName + ", " + employee.firstName;
    };

    const [columnDefs] = useState([
        { field: 'name', valueGetter: valueGetter },
        { field: 'jobRole' },
        { field: 'hireDate' },
        { cellRenderer: buttonCellRenderer, flex: 1, type: "rightAligned" }
    ]);

    /**
     * 
     * @param {EmployeeRecord} employee 
     */
    const updateEmployee = (employee) => {
        // TODO: Make API call to update the employee and use the response to update the list.
        if (employee.id === -1) {
            const updatedEmployees = [...employees, employee];
            setEmployees(updatedEmployees);
        }
        else {
            const updatedEmployees = employees.map((currentEmployee) => {
                if (currentEmployee.id === employee.id) {
                    return employee;
                }

                return currentEmployee;
            });

            setEmployees(updatedEmployees);
        }
        setIsOpen(false);
    };

    const cancelEditEmployee = () => {
        setIsOpen(false);
    };

    return (
        <Container sx={{ mt: 1, textAlign: "left" }}>
            <Grid container>
                <Grid item xs={10}>
                    <Typography variant='h5'>Employees</Typography>
                </Grid>
                <Grid item xs={2} sx={{ textAlign: "right" }}>
                    <Button variant='contained' startIcon={<PersonAddIcon />} onClick={addEmployeeClickHandler}>Employee</Button>
                </Grid>
            </Grid>

            <hr />

            <div className="ag-theme-material" style={{ height: '80vh' }}>
                <AgGridReact
                    rowData={employees}
                    columnDefs={columnDefs}
                    onRowClicked={rowClickHandler}
                    suppressCellFocus={true}                >
                </AgGridReact>
            </div>
            <Drawer open={isOpen} anchor={"right"} onClose={drawerCloseHandler} PaperProps={{ style: { width: '50%' } }}>
                <Employee data={currentEmployee} onUpdate={updateEmployee} onCancel={cancelEditEmployee}>
                </Employee>
            </Drawer>
        </Container>
    );
}

export default Employees;