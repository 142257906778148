import { Box, Button, TextField } from "@mui/material";
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { useState } from "react";
import { AgGridReact } from "ag-grid-react";

function EmployeeDocumentation() {

    const [documents] = useState([]);
    const [documentManaging, setDocumentManaging] = useState(false);

    // Add document item
    const handleCreateDocument = (event) => {
        setDocumentManaging(true);
    };

    // Document Grid Items
    const [columnDefs] = useState([
        { field: 'name' },
        { field: 'description' },
        { field: 'documentCount' }
    ]);

    const rowClickHandler = (params) => {
        setDocumentManaging(true);
    };

    // Document management
    const handleManageCancel = (event) =>{
        setDocumentManaging(false);
    };

    return (
        <Box>
            { documentManaging &&
                <Box>
                    <TextField
                        id="name"
                        name="name"
                        label="Name"
                        sx={{ mr: 1 }}>
                    </TextField>

                    <TextField
                        id="description"
                        name="description"
                        label="Description"
                        sx={{ mr: 1 }}>
                    </TextField>

                    <Button variant='contained' color="primary" onClick={handleManageCancel} sx={{mr:1}}>Ok</Button>
                    <Button variant='contained' color="secondary" onClick={handleManageCancel} >Cancel</Button>
                </Box>
            }
            {
                !documentManaging &&
                <Button variant='contained' startIcon={<UploadFileIcon />} onClick={handleCreateDocument} >Document</Button>
            }
            <div className="ag-theme-material" style={{ height: '20vh' }}>
                <AgGridReact
                    rowData={documents}
                    columnDefs={columnDefs}
                    onRowClicked={rowClickHandler}
                    suppressCellFocus={true}                >
                </AgGridReact>
            </div>
        </Box>
    );
}

export default EmployeeDocumentation;