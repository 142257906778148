export class EmployeeRecord{
    id = 0;
    firstName = "";
    lastName = "";
    phoneNumber = "";
    hireDate = "";
    jobRole = "";
    payRate = 0;

    static get default(){
        const record = new EmployeeRecord();
        record.id = -1;
        record.jobRole = JobRoles[0];
        record.hireDate = "2022-01-01";
        return record;
    }
}

export class EmployeeProps {
    /**
     * 
     * @param {EmployeeRecord} employee 
     */
    onUpdate = (employee) => {};
    onCancel = () =>{};
    data = new EmployeeRecord();
}

export const JobRoles = [
    "Hygentist",
    "Dental Assistant",
    "Front Office",
    "Back Office"
];

